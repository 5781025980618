import axios from 'axios';
import Cookie from 'js-cookie';

async function athleteNames(sport: string, schoolName: { queryKey: any[] }, { isAuthenticated }: { isAuthenticated: boolean}) {
    if (!isAuthenticated) return null;
    let queryParam = '';
    const accessToken = Cookie.get('access');
    const schoolNameLength = schoolName.queryKey[1].length
    if (schoolNameLength > 0) {
        const getSchoolName = schoolName?.queryKey && schoolName.queryKey[1];
        queryParam = `&school_name=${getSchoolName}`;
    }
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${accessToken}`,
            'Accept': 'application/json',
        },
    };

    if (sport === 'cricket') {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/athlete/all_cricket/${queryParam}`, config);

        if(schoolNameLength > 0) {
            const homeTeamPlayers = response.data.homeTeam;
            const awayTeamPlayers = response.data.awayTeam;
            return { homeTeamPlayers, awayTeamPlayers };
        } else {
            const homeTeamPlayers = response.data;
            return { homeTeamPlayers }
        }
    } else {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/athlete/athletes/?sport=${sport}${queryParam}`, config);
        
        if (sport === 'hockey') {
            if(schoolNameLength > 0) {
                const homeTeamPlayers = response.data.homeTeam;
                const awayTeamPlayers = response.data.awayTeam;
                return { homeTeamPlayers, awayTeamPlayers };
            } else {
                return { homeTeamPlayers: response.data.homeTeam }
            }
        } else if (sport === 'rugby' || sport === 'netball' || sport === 'tennis') {
            return response.data
        }
    }
}

export default athleteNames;