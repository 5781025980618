import axios from "axios";
import Cookie from "js-cookie";

type ID = "all" | "cricket"

const getAthletes = async (id: ID) => {
    const access = Cookie.get('access');
    if (!access) {
        throw new Error("Access token is missing. Please log in again.");
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${access}`,
            "Accept": "application/json",
        },
    };

    if (id === 'all') {
        const athleteResponse = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/athlete/athletes/`, config);

        return athleteResponse.data;
    } else if (id === 'cricket') {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/athlete/all_cricket/`, config);

        return response.data;
    }
}

export default getAthletes;