import { AuthState } from "@type/types";
import { useSelector } from "react-redux";

export const useIsAuthenticated = () => {
    const isAuthenticated = useSelector((state: AuthState) => state.auth)
    return isAuthenticated.isAuthenticated;
};
export const useAuthUser = () => {
    const user = useSelector((state: AuthState) => state.auth.user)
    const isSchoolAdmin = user?.is_schoolAdmin ?? false;
    const isCoach = user?.is_coach ?? false;

    return { user, isSchoolAdmin, isCoach };
};

export const useUserInfo = () => {
    const user = useSelector((state: AuthState) => state.auth.user);
    const email = user?.email
    const id = user?.id

    return { email, id };
}

export const useRugbyMatch = () => {
    const rugbyMatch= useSelector((state: any) => state.rugby)
    const rugbyMatchPresent = rugbyMatch?.present
    const rugbyMatchPast = rugbyMatch?.past
    const rugbyMatchFuture = rugbyMatch?.future

    return { rugbyMatchPresent, rugbyMatchPast, rugbyMatchFuture };
}

export const useRugbySettings = () => {
    return useSelector((state: any) => state.rugbySettings)
}

export const useNetballMatch = () => {
    const netballMatch = useSelector((state: any) => state.netball)
    const netballMatchPresent = netballMatch?.present
    const netballMatchPast = netballMatch?.past
    const netballMatchFuture = netballMatch?.future

    return { netballMatchPresent, netballMatchPast, netballMatchFuture };
}

export const useNetballSettings = () => {
    return useSelector((state: any) => state.netballStateReducer)
}