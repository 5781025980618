import { useNavigate } from "react-router";

const useNavigateHook = () => {
    const navigate = useNavigate();

    const goToPage = (route: string, options = {}) => {
        navigate(route, options)
    }

    const goHome = () => {
        navigate("/")
    }

    const goToDashBoard = () => {
        navigate('/dashboard')
    }

    const goBack = () => {
        navigate(-1)
    }

    return { goToPage, goHome, goBack, goToDashBoard };
}

export default useNavigateHook;