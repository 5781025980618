import axios from 'axios';
import Cookie from 'js-cookie';

export async function getTopPlayers(isAuthenticated: boolean) {
    const accessToken = Cookie.get('access');
    if (!isAuthenticated) {
        return null; // or you can return a specific type if required, e.g., {} or an error message
    }

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${accessToken}`,
            'Accept': 'application/json',
        },
    }
    const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/stats/top-players/`, config);

    return response.data;

}