import Cookies from 'js-cookie';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_FAIL,
    AUTHENTICATED_SUCCESS,
    LOGOUT,
    SIGNUP_FAIL,
    SIGNUP_SUCCESS,
    UPDATE_USER_FAIL,
    UPDATE_USER_SUCCESS,
    CREATE_COACH_FAIL
} from '../actions/types';

const initialState = {
    isAuthenticated: false,
    user: null,
    authError: null,
};


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true
            }

        case LOGIN_SUCCESS:
            Cookies.set('access', payload.access, {expires: 1, sameSite: 'strict', secure: true});
            Cookies.set('refresh', payload.refresh, {expires: 2, sameSite: 'strict', secure: true});

            return {
                ...state,
                isAuthenticated: true,
            }

        case SIGNUP_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
            }
        
        case USER_LOADED_SUCCESS:
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: payload
            }

        case AUTHENTICATED_FAIL:
        return {
            ...state,
            isAuthenticated: false
        }

        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null
            }

        case LOGIN_FAIL:
        case LOGOUT:
            Cookies.remove('access');
            Cookies.remove('refresh');
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        case CREATE_COACH_FAIL: 
            return {
                ...state,
                authError: payload
            }

        case SIGNUP_FAIL:
            Cookies.remove('access');
            Cookies.remove('refresh');
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                authError: payload
            }
        case UPDATE_USER_FAIL:
            return {
                ...state
            }

        default:
            return state
    }
}