import { atom } from 'jotai';

interface LoginModalState {
    open: boolean;
    navLink: string;
}

export const selectSportsAtom = atom<string[]>([]);
export const billingCycleAtom = atom<'monthly' | 'annually'>('monthly');
export const loginModalAtom = atom<LoginModalState>({
    open: false,
    navLink: "",
})