import axios from 'axios';
import Cookie from 'js-cookie';

async function coachListFunc() {
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${Cookie.get("access")}`,
            Accept: "application/json",
        },
    };

    try {
        const response = await axios.get(
            `${import.meta.env.VITE_BACKEND_URL}/api/coach/`,
            config
        );
        return response.data;
    } catch (err) {
        if (err instanceof Error) {
            throw new Error(err.message);
        }
    }
}

export default coachListFunc;