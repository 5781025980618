import { AthletesType } from '@type/addAthletes';
import axios from 'axios';
import Cookie from 'js-cookie';
import { UseFormSetValue } from 'react-hook-form';

export const createAthlete = async (data: AthletesType) => {
    const access = Cookie.get('access');
    if (!access) {
        throw new Error("Access token is missing. Please log in again.");
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${access}`,
            Accept: "application/json",
        },
        };

    try {
        const response = await axios.post(
            `${import.meta.env.VITE_BACKEND_URL}/athlete/athletes/`,
            data,
            config
        );
        return response.data; // Return the created data from the API response
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message);
            // Provide user feedback for submission error, e.g., show an error message to the user
        }
    }
};

export const updateAthlete = async (data: AthletesType, athleteData: AthletesType, handleEdit: () => void) => {
    const access = Cookie.get('access');
    if (!access) {
        throw new Error("Access token is missing. Please log in again.");
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${access}`,
            Accept: "application/json",
        },
    };

    try {
        await axios.put(
            `${import.meta.env.VITE_BACKEND_URL}/athlete/athletes/${
            athleteData.id
            }/`,
            data,
            config
    );
        handleEdit();
    } catch (error) {
        console.error(error);
    }
}

export const deleteAthlete = async (id: number) => {

}

export const athleteName = async (athleteId: number, setValue: UseFormSetValue<AthletesType>) => {
    const access = Cookie.get('access');
    if (!access) {
        throw new Error("Access token is missing. Please log in again.");
    }
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${access}`,
            Accept: "application/json",
        },
    };

    const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/athlete/athletes/${athleteId}/`,
        config
    );
    const individualData = response.data;

    setValue("name", individualData.name);
    setValue("surname", individualData.surname);
    setValue("gender", individualData.gender);
    setValue("race", individualData.race);
    setValue("date_of_birth.year", individualData.date_of_birth.year);
    setValue("date_of_birth.month", individualData.date_of_birth.month);
    setValue("date_of_birth.day", individualData.date_of_birth.day);
    setValue("height", individualData.height);
    setValue("weight", individualData.weight);
    setValue("cricket", individualData.cricket);
    setValue("rugby", individualData.rugby);
    setValue("tennis", individualData.tennis);
    setValue("netball", individualData.netball);
    setValue("hockey", individualData.hockey);

    return response.data;
}

export const athletesNames = async () => {

}