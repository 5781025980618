import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UseFormReset, UseFormSetValue } from "react-hook-form";
import { updateSchoolInfo } from "@reduxActions/auth";
import { useDispatch } from "react-redux";
import { useIsAuthenticated } from "./selectorHooks";
import coachListFunc from "@api/coachList"
import { activateUser, forgotPassWord, resetPasswordConfirm } from "@api/userFunctions";
import athleteNames from "@api/fetchAthletes";
import schoolList from "@api/getSchoolList";
import { getTopPlayers } from "@api/fetchTopPlayers";
import { getTopVenues } from "@api/fetchTopVenues";
import { athleteName, createAthlete, updateAthlete } from "@api/athletesApi"
import fetchStats from "@api/fetchStats"
import schoolInfo from "@api/schoolInfo";
import getAthletes from "@api/getAthletes";
import { handleDelete } from "@api/handleDelete";
import { UpdateSchoolInfoArgs, useAthleteNamesProps } from "@type/types";
import { AthletesType } from "@type/addAthletes";

export const useStatsHook = (sport: string) => {
    const { data, isLoading, error } = useQuery({
        queryKey: ['sportStats', sport],
        queryFn: () => fetchStats(sport),
        enabled: Boolean(sport),
    })

    return { data, isLoading, error };
}

export const useSchoolInfo = () => {
    const isAuthenticated = useIsAuthenticated();
    const { data, refetch, isLoading, isError, error } = useQuery({
        queryKey: ["schoolInfo"],
        queryFn: () => schoolInfo({ isAuthenticated }),
        enabled: !!isAuthenticated,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
    });

    return { data, refetch, isLoading, isError, error };
}

export const useCoachList = () => {
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["coaches"],
        queryFn: () => coachListFunc(),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    if (data) {
        sessionStorage.setItem(`coaches`, JSON.stringify(data));
    }

    return { data, isLoading, isError, error };
}

export const useUpdateSchoolInfo = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const mutation = useMutation({
        mutationFn: (args: UpdateSchoolInfoArgs) => updateSchoolInfo(args.id, args.formData, args.school_user, args.email, dispatch), // Pass dispatch to the mutation function,
        onSuccess: (data) => {
            queryClient.setQueryData(['schoolInfo'], data);
        },
        onError: (error) => {
            console.error("Error updating school info:", error);
        },
    });

    return {
        ...mutation, // Spread the mutation object to access isLoading, isError, etc.
    };
};

export const useVerify = (options = {}) => {
    return useMutation({
        mutationFn: activateUser,
        ...options,
    });
}

export const useResetPassword = (options = {}) => {
    return useMutation({
        mutationFn: resetPasswordConfirm,
        ...options,
    })
}

export const useForgotPassword = (options = {}) => {
    return useMutation({
        mutationFn: forgotPassWord,
        ...options,
    })
}

export const useAthleteNames = ({ search, sport }: useAthleteNamesProps) => {
    const isAuthenticated = useIsAuthenticated();
    const { data, isLoading } = useQuery({
        queryKey: ["athletes", search, sport],
        queryFn: (search) => athleteNames(sport, search, { isAuthenticated }),
    });

    return { data, isLoading };
}
export const useSchoolList = () => {
    const isAuthenticated = useIsAuthenticated();
    const { data } = useQuery({
        queryKey: ["school-list"],
        queryFn: () => schoolList({ isAuthenticated }),
    });

    return { data };
}   

export const useTopPlayers = () => {
    const isAuthenticated = useIsAuthenticated();
    const { data, isLoading, isError } = useQuery({
        queryKey: ["top-players"],
        queryFn: () => getTopPlayers(isAuthenticated),
    });

    return { data, isLoading, isError };
}

export const useTopVenues = () => {
    const isAuthenticated = useIsAuthenticated();
    const { data, isLoading, isError } = useQuery({
        queryKey: ["top-venues"],
        queryFn: () => getTopVenues(isAuthenticated),
    });

    return { data, isLoading, isError };
}

export const useAthleteMutation = (reset: UseFormReset<AthletesType>, setValue: UseFormSetValue<AthletesType>) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (formattedData: AthletesType) => createAthlete(formattedData),
        onSuccess: () => {
            reset();
            setValue("cricket", false);
            setValue("rugby", false);
            setValue("netball", false);
            setValue("tennis", false);
            setValue("hockey", false);
            setValue("date_of_birth.day", "");
            setValue("date_of_birth.month", "");
            setValue("date_of_birth.year", "");
            setValue('gender', "");
            queryClient.invalidateQueries({
                queryKey: ["athletes"], // Use an array for the query key
            });
        },
        onError: (error) => {
            console.error(error);
        },
    })

    return { mutation };
}

export const useUpdateAthleteMutation = (reset: UseFormReset<AthletesType>, athleteData: AthletesType, handleEdit: () => void) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (formattedData: AthletesType) => updateAthlete(formattedData, athleteData, handleEdit),
        onSuccess: () => {
            reset();
            queryClient.invalidateQueries({
                queryKey: ["athletes"], // Use an array for the query key
            });
        },
    });

    return { mutation };
}

export const useIndividualAthlete = (id: number, setValue: UseFormSetValue<AthletesType>) => {
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["athletes", id],
        queryFn: () => athleteName(id, setValue),
        enabled: !!id,
    });

    return { data, isLoading, isError, error };
}

export const useAthleteNameSearch = (sport: "all" | "cricket") => {
    const { isLoading, isError, error, data, refetch } = useQuery({
        queryKey: ["athletes"],
        queryFn: () => getAthletes(sport), 
        refetchOnMount: false,
        refetchOnWindowFocus: false, 
    })

    return { data, isLoading, isError, error, refetch };
}

export const useDeleteAthlete = () => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (id: string) => handleDelete(id),
        onSuccess: async () => {
            // Invalidate and refetch in sequence
            await queryClient.invalidateQueries({ queryKey: ["athletes"] });
            await queryClient.invalidateQueries({ queryKey: ["cricket"] });
            
        },
        // Add error handling
        onError: (error) => {
            console.error("Failed to delete athlete:", error);
            // You might want to show an error toast/notification here
        }
    });
    return { mutation };
}