import { checkAuthenticated, load_user } from "@reduxActions/auth";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIsAuthenticated } from "./selectorHooks";

export const useInitializeApp = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!isAuthenticated) {
            dispatch(checkAuthenticated());
            dispatch(load_user());
        }
    }, [dispatch, isAuthenticated]);

    return queryClient; // Optional: return QueryClient if needed
};