import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import {
  ThemeProvider,
  alpha,
  createTheme,
  getContrastRatio,
} from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import store from "@utils/store";
import { inject } from "@vercel/analytics";
// import * as Sentry from "@sentry/react";
import { Toaster } from "@ui/toaster"
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from "react-router";

// Sentry.init({
//   dsn: "https://becf816b1ee87b35ccf00e0cfc37c68a@o4507997682204672.ingest.de.sentry.io/4507997695967312",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.browserProfilingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [/^https:\/\/scoringwiz\.co.za\/*/],
//   // Set profilesSampleRate to 1.0 to profile every transaction.
//   // Since profilesSampleRate is relative to tracesSampleRate,
//   // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
//   // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
//   // results in 25% of transactions being profiled (0.5*0.5=0.25)
//   profilesSampleRate: 1.0,
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const netballMain = "#e55983";

const theme = createTheme({
  palette: {
    primary: {
      main: "#429EBD",
      light: "#9FE7F5",
      dark: "#053F5C",
    },
    secondary: {
      main: "#F27F0C",
      light: "#F7AD19",
      dark: "#FD5901",
    },
    netball: {
      main: netballMain,
      light: alpha(netballMain, 0.9),
      dark: alpha(netballMain, 0.1),
      contrastText:
        getContrastRatio(netballMain, "#fff") > 3.0 ? "#fff" : "#111",
    },
  },
});

inject();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 60 * 1000, // 30 minutes (staleTime)
      cacheTime: 8 * 60 * 60 * 1000, // 8 hours (cacheTime)
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
            <Toaster />
          </BrowserRouter>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
