import axios from "axios";
import Cookie from "js-cookie";

export const handleDelete = async (id: string) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${Cookie.get("access")}`,
            "Accept": "application/json",
        },
    };

    try {
        await axios.delete(
            `${import.meta.env.VITE_BACKEND_URL}/athlete/athletes/${id}/`,
            config
        );
    } catch (e) {
        if (e instanceof Error) {
            throw new Error(e.message);
        }
    }
};

