import axios from "axios";
type ActivateProps = {
    uid: string;
    token: string;
}

type ResetProps = ActivateProps & {
    new_password: string;
    re_new_password: string;
}

export const activateUser = async ({ uid, token }: ActivateProps) => {
    const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/auth/users/activation/`,
        { uid, token },
        { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
};

export const resetPasswordConfirm = async ({ uid, token, new_password, re_new_password }: ResetProps) => {
    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/auth/users/reset_password_confirm/`,
        body,
        { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
}

type ForgotPasswordResponse = {
    detail: string; // Message from Djoser on success
};

export const forgotPassWord = async ({ email }: { email: string }): Promise<ForgotPasswordResponse> => {
    const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/auth/users/reset_password/`,
        { email },
        { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
}