import axios from 'axios';
import Cookie from 'js-cookie';

export async function getTopVenues(isAuthenticated: boolean) {
    if (!isAuthenticated) {
        return null; // or you can return a specific type if required, e.g., {} or an error message
    }

    const accessToken = Cookie.get('access');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${accessToken}`,
            'Accept': 'application/json',
        },
    }

        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/stats/venue/most_wins_losses/`, config);

        return response.data;
}