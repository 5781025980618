import axios from 'axios';
import Cookie from 'js-cookie';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_FAIL,
    AUTHENTICATED_SUCCESS,
    LOGOUT,
    SIGNUP_FAIL,
    SIGNUP_SUCCESS,
    UPDATE_USER_FAIL,
    UPDATE_USER_SUCCESS,
    CREATE_COACH_FAIL
} from './types';

export const load_user = () => async dispatch => {
    if (Cookie.get('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${Cookie.get('access')}`,
            }
        };

        try {
            const res = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/auth/users/me/`, config);
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
    } else {
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
};

export const checkAuthenticated = () => async dispatch => {
    if (Cookie.get('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${Cookie.get('access')}`
            }
        }; 

        const body = JSON.stringify({ token: Cookie.get('access') });

        try {
            const res = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/auth/jwt/verify/`, body, config)

            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        } catch (err) {
            const refreshConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            };
            const refreshBody = JSON.stringify({ refresh: Cookie.get('refresh') });
            const refreshRes = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/auth/jwt/refresh/`, refreshBody, refreshConfig);
            if (refreshRes.data.access) {
                // Refresh successful, update the access token in local storage
                Cookie.set('access', refreshRes.data.access, { expires: 1, sameSite: 'strict' });
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                // Refresh failed, token is expired or invalid
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        }

    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }
};

export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email, password });

    try {
        const res = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/auth/jwt/create/`, body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

        dispatch(load_user());
    } catch (e) {
        dispatch({
            type: LOGIN_FAIL,
        })
        throw new Error(e);
    }
};

export const updateSchoolInfo = async(
    id,
    formData,
    school_user,
    email,
    dispatch
    ) => {
        const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `JWT ${Cookie.get('access')}`,
        },
    };

    formData.append('school_user', school_user);
    formData.append('email', email);

    try {
        const res = await axios.put(`${import.meta.env.VITE_BACKEND_URL}/api/school/update/${id}/`, formData, config);
        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: UPDATE_USER_FAIL,
        });
        throw e;
    }
};

export const signup = (
    school_type, 
    school_name, 
    address, 
    district, 
    province, 
    phone_number,
    email, 
    password,
    re_password, 
    payment_options,
    cricket, 
    rugby, 
    netball, 
    tennis,
    hockey,
    is_coach,
    is_schoolAdmin,
    school_logo) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const customUserBody = JSON.stringify({
        email, 
        password, 
        re_password,
        is_coach,
        is_schoolAdmin,
    });

    let customUserId;

    try {
        const res = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/auth/users/`, customUserBody, config);
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        });
        customUserId = res.data

        const formData = new FormData();
        formData.append('school_type', school_type);
        formData.append('school_name', school_name);
        formData.append('address', address);
        formData.append('district', district);
        formData.append('province', province);
        formData.append('phone_number', phone_number);
        formData.append('payment_options', payment_options);
        formData.append('cricket', cricket);
        formData.append('rugby', rugby);
        formData.append('netball', netball);
        formData.append('tennis', tennis);
        formData.append('hockey', hockey);
        formData.append('school_user', customUserId.id);
        formData.append('email', customUserId.email);

        if (school_logo) {
            formData.append('school_logo', school_logo);
        }

        const configSchool = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        try {
            await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/school/create/`, formData, configSchool);
        } catch (error) {
            await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/school/rollback/`, { user_id: customUserId.id }, config);
            dispatch({
                type: SIGNUP_FAIL,
                payload: error.response.data
            })
            throw new Error(error);
        }
    } catch (e) {
        dispatch({
            type: SIGNUP_FAIL,
            payload: e.response.data
        })
        throw new Error(e.response);
    }
};

export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    });
};

export const createCoaches = (e) => {
    return {
        type: CREATE_COACH_FAIL,
        payload: e.response.data
    }
}

export const clearCreateCoachesError = () => {
    return {
        type: CREATE_COACH_FAIL,
        payload: null
    }
}