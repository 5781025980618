import { useSetAtom } from "jotai";
import { loginModalAtom } from "../utils/atoms";
import useNavigateHook from "@hooks/useNavigateHook";
import { useIsAuthenticated } from "@hooks/selectorHooks";
import { MouseEvent } from "react";

const useHandleClick = () => {
    const setLoginModal = useSetAtom(loginModalAtom);
    const isAuthenticated = useIsAuthenticated();
    const { goToPage } = useNavigateHook();

    return (e: MouseEvent<HTMLButtonElement>) => {
        console.log(e.currentTarget.id);
        const scoringRoutes: Record<string, string> = {
            rugby: "/scoring/rugby",
            netball: "/scoring/netball",
            tennis: "/scoring/tennis",
            cricket: "/scoring/cricket",
            hockey: "/scoring/hockey",
        };

        const navLink = scoringRoutes[e.currentTarget.id];
        console.log(navLink);
        if (!isAuthenticated) {
            setLoginModal((prev) => ({
                ...prev,
                open: true,
                navLink: e.currentTarget.id,
            }));
        } else if (navLink) {
            goToPage(navLink);
        }
    };
};

export default useHandleClick;