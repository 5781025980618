import axios from 'axios';
import Cookie from 'js-cookie';

async function schoolList({ isAuthenticated }: { isAuthenticated: boolean }) {
    if (!isAuthenticated) return null;
    const accessToken = Cookie.get('access');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${accessToken}`,
            'Accept': 'application/json'
        },
    };

    const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/school/list`, config);
    return response.data;
}

export default schoolList;