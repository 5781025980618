import React, { useEffect, useState } from "react";

const LazySection = ({ children }: { children: React.ReactNode }) => {
	const [isInView, setIsInView] = useState(false);
	const sectionRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsInView(true);
					observer.disconnect(); // Stop observing once the section is loaded
				}
			},
			{ threshold: 0.1 } // Adjust threshold as needed
		);

		if (sectionRef.current) observer.observe(sectionRef.current);

		return () => observer.disconnect();
	}, []);

	return <section ref={sectionRef}>{isInView && children}</section>;
};

export default LazySection;
