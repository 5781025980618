import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
	"inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
	{
		variants: {
			variant: {
				default:
					"bg-primary text-primary-foreground shadow hover:bg-primary/90 hover:text-primary uppercase",
				destructive:
					"bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90 uppercase",
				outline:
					"border border-input bg-background shadow-sm hover:bg-accent hover:text-black uppercase",
				secondary:
					"bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80 hover:text-secondary uppercase",
				ghost: "hover:bg-accent hover:text-accent-foreground uppercase",
				link: "text-primary underline-offset-4 hover:underline uppercase",
				rugby: "bg-primaryDark uppercase shadow rounded-full text-primary-foreground hover:bg-primary-foreground hover:text-primaryDark",
				tennis: "bg-primaryDark uppercase shadow rounded-full text-primary-foreground hover:bg-primary-foreground hover:text-primaryDark",
				hockey: "bg-primaryDark uppercase shadow rounded-full text-primary-foreground hover:bg-primary-foreground hover:text-primaryDark",
				cricket:
					"bg-primaryDark uppercase shadow rounded-full text-primary-foreground hover:bg-primary-foreground hover:text-primaryDark",
				netball:
					"bg-netball text-primary-foreground shadow hover:bg-netballDark uppercase",
				success:
					"bg-green-500 text-primary-foreground hover:bg-green-600 uppercase shadow",
				danger: "bg-red-500 text-primary-foreground hover:bg-red-600 uppercase shadow",
			},
			size: {
				default: "h-9 px-4 py-2",
				sm: "h-8 rounded-md px-3 text-xs",
				lg: "h-10 rounded-md px-8",
				icon: "size-9 rounded-full",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : "button";
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				{...props}
			/>
		);
	}
);
Button.displayName = "Button";

export { Button, buttonVariants };
