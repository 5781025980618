import axios from "axios";
import Cookie from "js-cookie";

const fetchStats = async (id: string) => {
    const accessToken = Cookie.get('access');
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `JWT ${accessToken}`,
        },
    };

    if (id === 'tennis') {
        try {
            const response = await axios.get(
                `${import.meta.env.VITE_BACKEND_URL}/stats/athletes/${id}/stats/`,
                config
            );
            return response.data;
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(e.message);
            }
        }
    } else {
        try {
            const schoolStats = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/stats/school/${id}/`, config);
            const playerStats = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/stats/athletes/${id}/stats/`, config);

            return {schoolStats: schoolStats.data, playerStats: playerStats.data};
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(e.message);
            }
        }
    }

}

export default fetchStats;