import axios from 'axios';
import Cookie from 'js-cookie';

async function schoolInfo({ isAuthenticated }: { isAuthenticated: boolean }) {
    if (!isAuthenticated) return null;

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${Cookie.get('access')}`,
            'Accept': 'application/json'
        },
    };
    
    try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/school/`, config);

        if(response.statusText !== "OK") {
            throw new Error('Failed to fetch school info');
        }
        const data = await response.data;
        sessionStorage.setItem('schoolInfo', JSON.stringify(data)); // Cache the fresh data
        return data

    } catch (e) {
        if (e instanceof Error) {
            throw new Error(e.message);
        }
    }
}

export default schoolInfo